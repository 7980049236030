#authorize .btn-round {
    border-radius: 30px; }

#authorize .btn {
    padding: 12px 40px !important;
    font-size: 20px;
    font-weight: 600; }

#authorize .btn-secondary {
    background-color: #4db9fe !important;
    border: none; }
.account-wrap {
    background-image: url("./images/bg-pattren.png");
    min-height: 100vh;
    height: 100%;
    position: relative; }
.account-wrap::before {
    content: "";
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-size: 100%; }
.account-wrap.signin::before {
    background: url("./images/signin-bg.png");
    background-repeat: no-repeat;
    background-size: contain; }
.account-wrap .logo {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 1; }
.account-wrap .logo img {
    max-width: 200px; }

.account-form-wrap.form-signup {
    padding-top: 200px; }
.account-form-wrap .account-title {
    margin-bottom: 25px; }
.account-form-wrap .account-title h3 {
    font-weight: bold;
    color: #2e6388;
    font-size: 38px;
    margin-bottom: 0; }
.account-form-wrap .account-title p {
    color: #2e6388;
    font-size: 20px;
    margin: 0; }

@media screen and (min-width: 1365px) {
    .account-wrap::before {
        background-size: 70% !important; }
    .account-wrap.reset-password::before {
        background-size: 40% !important; }
    .account-wrap.student-profile::before {
        background-size: 45% !important; } }
@media screen and (max-width: 767px) {
    .account-form-wrap.form-signup {
        padding-top: 30px; }
    .account-form-wrap .account-title {
        margin-bottom: 30px; }
    .account-form-wrap .account-title h3 {
        font-size: 28px; }

    .account-wrap {
        background-image: url("./images/bg-pattren.png");
        min-height: 100vh;
        height: 100%;
        position: relative; }
    .account-wrap::before {
        display: none; }
    .account-wrap .logo {
        position: relative;
        text-align: center; } }

.loading-area{
    padding-top: 50px;
}
.loading-area p{
    font-size: large;
}