
.dashboard {
    width: 100%;
    min-height: 75vh;
    margin-top: 50px;

    /* This is what centers it */
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard h1 {
    color: #fff;
    font-family: 'Museo Sans', Avenir, 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 900;
    font-size: 15vmin;
    text-align: center;
    font-size: 15vmin; /* If you want to be extra-fancy */
    line-height: 110%;
    text-shadow: 0.05em 0.05em 0.1em #000;
    text-shadow: 0 0.025em 0.05em rgba(0, 0, 0, 0.75), 0 0.05em 0.1em rgba(0, 0, 0, 0.5);
}