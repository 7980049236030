.nav-bar {
  width: calc(100% - 18px);
  padding: 0 9px;
  height: 44px;
  border-bottom: 1px solid #dadce0;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    cursor: pointer;
    color: #666;
    .icon {
      font-size: 18px;
      margin-top: -2px;
      margin-bottom: -2px; }
    .text {
      font-size: 12px; } }
  .item:hover {
    color: #000; } }
