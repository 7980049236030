@font-face {
  font-family: ProximaNovaRegular;
  src: url('./assets/fonts/ProximaNovaRegular.ttf');
}

body {
  margin: 0;
 font-family: ProximaNovaRegular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.swal2-confirm{
  background-color: #a2b1bb;
}

.typingBottomShow{
  font-size: 10px;
  margin-left: 20px;
  position: absolute;
  bottom: 3px;
}

/* ************* */
.file-container{
  position: relative;
  z-index: 999;
  color: rgb(102, 102, 102);
}
.button.smile{
   color: rgb(102, 102, 102);
}
.file-elements{
  display: none;
  position: absolute;
top: -95px;
  left: -110px;
}
.file-elements.active{
  display: block;
}
.file-elements > div{
display: flex;
justify-content: space-between;
align-items: center;
width: 150px;
margin-bottom: 4px;
}

.file-container .file-elements span {
    cursor: pointer;
    background-color: #da7d02;
    width: 45px;
    height: 45px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center; 
    border-radius: 50%;
    color: #fff;
    font-weight: bolder;
}
.file-container .file-elements small {
    background: #fff;
    padding: 7px 20px;
    border: 1px solid #fff;
    border-radius: 23.5px;
    margin-right: 6px;
    box-shadow: 0 0 9px 0.9px rgb(0 0 0 / 20%);
    color: #000;
    font-weight: 600;
    width: 54px;
    text-align: center;
    font-size: 13px;
    width: 100px;
}
.file-container .file-elements span.file-link {
    background-color: #3fac00;
 
}

.file-container .file-elements span.imge-link {
    background-color: #f23553;
}

/* ************* */

.SideBarImgeWrap{
  height: 80px !important;
  padding: 10px 0px !important;
  border: none !important;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}

.SideBarImgeWrap .item.active .toltipsContainer{
display: block;

}
.top-bar .text .title{
  font-size: 16px !important;
}
 



.ReactPlaceholder-x {
    padding: 8px 13px;
    border-top: 1px solid gainsboro;
    border-bottom: none;
}

.ReactPlaceholder-x:last-child {
    border-bottom: 1px solid gainsboro;
}

.SideBarImgeWrap .icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideBarImgeWrap > div  div:first-child{
  padding: 10px;
  border-radius: 50%;
  width: 55px;
  height: 55px;
}
.SideBarImgeWrap > div{
  position: relative;
  width: auto !important;
}
.SideBarImgeWrap > div:hover .toltipsContainer{
  display: block;
  z-index: 9999
}
.farwardModal .modal-header{
  padding: 8px

}
.farwardSelectedImges{
  display: flex;
  flex-wrap: wrap;
 
}
.farwardSelectedImges > div{
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  border: 1px solid #e2e2e2;
  background-color: white;
  margin-right: 5px;
  margin-top: 2px;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.farwardSelectedImges > div >div{
  cursor: pointer;
  position: absolute;
  top: -3px;
  right: -2px;
  font-size: 10px;
  background-color: darkcyan;
  padding: 2px;
  color: #ffffff; 
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.farwardSelectedImges > div >div:first-child{
    border: 1px solid #e2e2e2;
    background-color: #666666;
}
/* ************ */
.ImgeOuter{
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #dcdcdc;
    margin: 0px 12px;
}
.bubble-left.replys::before{
  display: none !important;
}
.ImgeOuter.bubble-right.right.replys{
  border-left: 5px solid #42B8DF ;
}
.ImgeOuter.bubble-left.left.replys{
  border-right: 5px solid #42B8DF ;
}
.ImgeOuter::before{
display: none;
}
.ImgeContainerChat{
  height: 113px;
  overflow: hidden;
margin: -14.2px -12.3px;
border-radius: 5px;
margin-bottom: -3px;

}
.ImgeContainerChat img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* ************ */



.farwardSelectedImges > div img{
  width: 100%;
  height: 100%;
 object-fit: cover;
 border-radius: 50%;
}
.farwardSearch{
  display: flex;
  align-items: center;
   font-size: 15px;
   margin: 5px 10px;


   /* background: white !important;
    padding: 0px 10px;
    border: 1px solid #c2c2c2 !important;
    border-radius: 5px; */
    color: #c2c2c2 !important;

}
.farwardSearch > div:last-child{
  flex-grow: 1;
  padding-left: 5px;
}

.farwardSearch > div:last-child input{
  width: 100%;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
  border: none !important;
}
.farwardSearch > div:last-child input:focus{
outline: none;
}
.farwardSearch > div:last-child input::placeholder{
  color: #c2c2c2;
}

.message-details{
  display: none;
}

.message:hover .message-details{
  display: block;
}

.imojeShowFlex{
  display: flex;
  margin-top: -7px;
  z-index: 99;
justify-content: space-between;
  flex-direction: row-reverse;

}
.imojeShowFlex.right{
  justify-content: space-between;
  margin-left: 25px;
  flex-direction: unset;

}
.imojeShowFlex > div:first-child{
margin-right: 25px;
display: flex;
}
.imojeShowFlex > div:first-child > div{
  background: #fff;
  padding: 1px 6px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 17%);
    border-radius: 13px;
    margin-right: 3px;
    font-size: 14px;
    width: 50px;
    align-items: center;
  }
  .imojeShowFlex > div:first-child > div{
display: flex;
margin-right: 6px;
/* padding: 2px; */
  }
.imojeShowFlex img{
height: 25px;
display: inline-block;
}


.SideBarImgeWrap > div .toltipsContainer{
  display: none;
  position:absolute !important;
  padding: 3px 11px;
  top: 61px;
    left: 5px;
  border-radius: 5px;
  background-color: #f13554 !important;
  color: white !important;
  z-index: 9;
}
.SideBarImgeWrap > div .toltipsContainer::before{
   content: "";
   width: 10px;
   height: 10px;
   transform: rotate(45deg);
  top: -5px;
    left: 32px;
   background-color: #f13554;
   display: block;
   position: absolute;
}


.roomsContainer .profile{
  width: 50px !important;
  height: 50px !important;
}
.roomsContainer .title{
     font-size: 15px !important;
    color: #312f2f !important;
}


.SideBarImgeWrap > div:first-child .toltipsContainer{
  width: 117px;
  background-color: #3fac00 !important;
}

.SideBarImgeWrap > div:first-child .toltipsContainer::before{
  background-color: #3fac00 !important;
}


/* ************** */
.SideBarImgeWrap > div:nth-child(2) .toltipsContainer{
  width: 80px;
  background-color: #f13554 !important;
}

.SideBarImgeWrap > div:nth-child(2) .toltipsContainer::before{
  background-color: #f13554 !important;
}
.SideBarImgeWrap > div:nth-child(3) .toltipsContainer{
  width: 85px;
  background-color: #be8635 !important;
}

.SideBarImgeWrap > div:nth-child(4) .toltipsContainer::before{
  background-color: #2b6289 !important;
}
.SideBarImgeWrap > div:nth-child(4) .toltipsContainer{
  width: 124px;
  left: -65px;
  background-color: #2b6289 !important;
}
.SideBarImgeWrap > div:nth-child(4) .toltipsContainer::before{
  left: 97px;
}

.SideBarImgeWrap > div:nth-child(3) .toltipsContainer::before{
  background-color: #be8635 !important;
}

/* ************** */


.SideBarImgeWrap > div:nth-child(2) .toltipsContainer{
  width: 80px;
}

.msgCount{
         background: #f13554;
    color: white;
    position: absolute;
    border-radius: 50%;
    padding: 3px;
    font-size: 8px;
    top: -27px;
    width: 15px;
    height: 15px;
    text-align: center;

}




/* .SideBarImgeWrap > div  div:first-child img{
width: 25px;
} */

.SideBarImgeWrap > div:first-child >div{
background-color: #d7eec9;
}
.SideBarImgeWrap > div:nth-child(2) >div{
background-color: #ffe6e9;
}

.SideBarImgeWrap > div img{
/* height: 20px !important; */
width: 30px !important;
}
.SideBarImgeWrap > div:first-child img{
/* height: 20px !important; */
width: 27px !important;
}

.SideBarImgeWrap > div:nth-child(3) >div{
background-color: #faeedd;
}
.SideBarImgeWrap > div:nth-child(4) >div{
background-color: #dcf0fe;
}




/* ************ */

.iframLink{
  display: flex;
  flex-direction: column-reverse;
}
/* ************ */
.side-search-bar {
    display: flex;
    border: 1px solid #d7d7d7;
    padding: 5px 12px 5px 4px;
    border-radius: 5px;
    margin-left:5px;
    cursor: pointer;
}
.side-search-bar span {
    padding: 0 5px;
    display: block;
}
.side-search-bar svg {
    color: #cecaca;
    font-weight: 700;
}
.side-search-bar input {
  border: none !important;
  color: #1b1818;
  outline: none !important;
}
.side-search-bar input::placeholder {
  color: #c2c2c2;
  font-size: 15px;
}

/* ********rounded */
.side-search-bar.bordered span:nth-child(2) {
display: none;
}
.side-search-bar.bordered span:nth-child(3) {
display: none;
}
.side-search-bar.bordered  {
padding: 0px;
border-radius: 50%;
}
/* ************ */

.serchContainers{
  display: flex;
  align-items: center;
  margin: 20px 14px;
}
.serchContainers > div:first-child{
 width: 90%;
}
.searchWrap{
    background: white !important;
    padding: 0px 10px;
    border: 1px solid #c2c2c2 !important;
    width: 90% !important;
    margin: auto;
    border-radius: 5px;
    color: #c2c2c2 !important;
}
.Favorates{
  display: block;
  cursor: pointer;
   color: #c2c2c2 !important;

}

.serchDropDownwrap{
  position: relative;
}

.serchDropDownContainer{
  display: none;
  position: absolute;
  background: white;
  border: 1px solid #d7d7d7;
  right: -11px;
  width: 149px;
  top: 37px;
  z-index: 9;
  border-radius: 6px;
    font-family: ProximaNovaRegular;
    color: black;
  
}

.serchDropDownContainer.active{
display: block;

}
.serchDropDownContainer > div{
  display: flex;
  flex-direction: column;
  /* padding: 5px; */
}
.serchDropDownContainer > div > div{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-radius: 10px; */
  padding: 3px;
}
.serchDropDownContainer > div img{
  width: 20px;
  margin: 0px 8px;
}

.searchDrop-icon{
  cursor: pointer;
}

.serchDropDownContainer > div > div:first-child{
  color:#2b6289 ;
}
.serchDropDownContainer > div > div:nth-child(2){
  color:#fe3274 !important ;
}
.serchDropDownContainer > div > div:nth-child(3){
  color:#494063 !important;
}

/*  */
.serchDropDownContainer > div > div:first-child:hover{
  background: #dcf0fe;
  color:#2b6289 ;
}
.serchDropDownContainer > div > div:nth-child(2):hover{
  background: #FFE9EC;
  color:#fe3274 !important ;
}
.serchDropDownContainer > div > div:nth-child(3):hover{
  background: #EEE9FF;
  color:#494063 !important;
}
/*  */


/* **** */
.serchDropDownContainer > div > div.activee:first-child{
  background: #dcf0fe;
}
.serchDropDownContainer > div > div.activee:nth-child(2){
  background: #FFE9EC;
}
.serchDropDownContainer > div > div.activee:nth-child(3){
  background: #EEE9FF;
}


/* **************** */

.userStatuschat{
     padding: 4px 8px;
    border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  
}
.userStatuschat img{
  height: 16px;
  margin: 0px 2px;
}

.userStatuschat.a{
  background: #dcf0fe;
  color:#2b6289 ;
  cursor: pointer;

}
.userStatuschat.b {
  background: #FFE9EC;
  color:#fe3274 !important ;
}
.userStatuschat.c{
  background: #EEE9FF;
  color:#494063 !important;
}
/* **************** */
.info-dots{
  border: 1px solid #8c8c8c;
  padding: 0px !important;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  padding-left: 50px;
  background: white !important;
}
.info-dots svg{
width: 12px;
} 


.userInfoConaterLive {
  display: none;
    position: absolute;
    background: white;
    border: 1px solid #d7d7d7;
    right: 30px;
    width: 160px;
    top: -23px;
    z-index: 9;
    border-radius: 6px;
}
/* .userdot-wrap:hover .userInfoConaterLive{
  display: block;
} */
.userdot-wrap {
    padding-left: 11px;
}

.userInfoConaterLive::before {
     content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    right: -7px;
    top: 26px;
    transform: rotate(
135deg
);
}
.userInfoConaterLive > div {
   display: flex;
   flex-direction: column;
  
}
.userInfoConaterLive > div div {
padding: 3px 0px 3px 34px;
cursor: pointer;
 position: relative;
}
.userInfoConaterLive > div div:hover {
background: #d7eec9;
}
.userInfoConaterLive > div div::before {
position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    top: 11px;
    left: 10px;
    border-radius: 50%;
}
.userInfoConaterLive > div div:nth-child(1):before {
    background: #05964b;
}
.userInfoConaterLive > div div:nth-child(2):before {
    background: #ff9c25;
}
.userInfoConaterLive > div div:nth-child(3):before {
    background: #ff0000;
}
/* **************** */
.topUserInfoBar{
  margin-right: auto;
}

.topBarComputerUmer{
  padding: 10px 0px;
  background: #ecececfa !important ;
  border-bottom: none !important;
  min-height: 70px !important;
  max-height: 70px !important;
}

.topUserInfoBar h4,p{
  padding: 0px !important;
  margin: 0px !important;
}

/* .topUserInfoBar p{
 
  font-weight: bold !important;
} */
.topUserInfoBar h4{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: bolder;
    font-size: 15px !important;
    margin-bottom: 2px;
    text-transform: uppercase;
}


.topUserInfoBar p{
 font-family: ProximaNovaRegular;
  font-size: 12.1px;
color: #2b628a;
    font-weight: 500;
    font-style: italic;
}
/* **************** */
.profile {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
border:1px solid #e2e2e2;
background-color: white;
}
.theme.light .panel {
  height: auto !important;
    background: white;
    margin: 10px;
    margin-right: 0px;
    background: red;
    border-radius: 5px;
    overflow: hidden;
}
#root,.theme{
   background: #e7e7e7 !important;
}
/* **************** */
.roomsContainer{
  padding: 40px 17px !important;
  box-sizing: border-box;

}
.roomsContainer:hover{
  background: #e2ecf7 !important;
}
.sidetickDate{
  display: flex;
  flex-direction: column;
      font-weight: 500;
    color: #1b1818 !important;
}
.sidetickDate img{
 margin-top: 10px;
}

.main {
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
    background: white !important;
}


.messages-wrapper .message .content-x {
  min-width: unset !important;
  max-width: unset !important;
    width: 52% !important;

}

.theme.light .details{
  /* display: none; */
}

.mediaContainer-wraper{
  display: flex;
  justify-content: flex-start;
  padding: 20px
}

.mediaContainer-wraper .medaImges {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column-reverse; */
}



.bubble.right a{
  color: rgb(88, 88, 194) !important;
  text-decoration: none !important;
}
.bubble.left a{
  color: rgb(88, 88, 194) !important;
    text-decoration: none !important;
}

.mediaContainer-wraper .row img{
   height: 100px;
   padding: 5px;
   margin: 5px;
   border: 1px solid gainsboro;
   border-radius: 5px;
}


.manu-drop-chat > div{
  width: 24px;
  height: 24px;
  border: 1px solid #cecaca;
  border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 2px;
}

.manu-drop-chat > div svg{
transform: rotate(90deg);

    color: #cecaca;

}


.chatAnimatedLoaders > div{
  padding: 10px 15px !important;
}
.chatAnimatedLoaders > div.right > div{
  padding: 10px !important;
  flex-direction: row-reverse !important;
}
.chatAnimatedLoaders > div.right > div > div{
  margin-left: 30px !important;
}

/* width */
.panel .rooms::-webkit-scrollbar {
  width: 0px;
}

/* Track */


/* ************************* */
.applogoContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px;
  background: white;
  border-bottom:  1px solid #e3e3e3;
}
.applogoContainer img{
 height: 50px; 
}
.noti-info-wraper{
  
  display: flex;
  justify-content: center;
  align-items: center;
      color: black;
    font-weight: 500;
}
.noti-info-wraper> div{
cursor: pointer;
}
.noti-info-wraper> div:first-child{
  margin-right: 20px;
}
.notifi-wrap{
  position: relative;
}
.noti-info-wraper svg{
  margin-right: 10px;
}
.notiCount {
    background: #ff0000;
    position: absolute;
    font-size: 9px;
    color: white;
    padding: 2px;
    border-radius: 15px;
    top: -11px;
    left: 8px;
}

.setting-wrap-top{
  position: relative;
  padding-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
}


.setting-wrap-top:hover >  div:first-child  {
    color: #349bf4 !important;
    /* text-decoration: none; */
}

.joinGrouped{
font-size: 12px;
  background-color: #d7eec9;
  color: #3fac00;
  padding: 1px 6px;
  cursor: pointer;
}
.joinGroup{
  font-size: 12px;
    background-color: #faeedd;
  color: #be8635;
    padding: 1px 6px;
    cursor: pointer;
}
 
.setting-DropDown{
  display: none;
  position: absolute;
  min-width: 250px;
  padding: 0px !important;
  background: white;
  border: none !important;
  top: 30px !important;
  right: 1px !important;
  z-index: 9;
  border-radius: 6px !important;
  /* overflow: hidden; */
  border: 1px solid #d7d7d7 !important;
  z-index: 999999 !important;
  color: black;
}
.setting-wrap-top:hover .setting-DropDown {
  display: block !important;
}

.setting-DropDown > div {
display: flex;
flex-direction: column;
}
.setting-DropDown >div> div {
/* padding: 3px 10px;
border-bottom: 1px solid #d7d7d7 !important;
display: flex;
align-items: center;
justify-content: space-between; */

display: block;
    border-bottom: 1px solid #e3e3e3;
    padding: 6px 17px;
    font-weight: 700;
    color: #8c8c8c;
    cursor: pointer;
    z-index: 9999;
    margin-right: 0;
    font-size: 15px;

}
.setting-DropDown >div> div:hover {
background: rgba(52,154,244,.1803921568627451);
}
.setting-DropDown >div> div:last-child {
/* padding: 3px 10px; */
border-bottom: none !important;
}

.setting-DropDown::before {
       content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    right: 15px;
    top: -7px;
    transform: rotate(44deg);
    z-index: -1;

}

/* css loader */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 70px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2b6289;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 10px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/* css loader */


.togglersetting-wrap{
  border: 1.5px solid #d7d7d7;
  border-radius: 20px;
  float: right;
  width: 40px;
  height: 19px;
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  
}
.togglersetting-wrap span{
  position: absolute;
  left: 1.5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #8C8C8C;
  border-radius: 50%;
  transition: 0.5s;
  top: 0px;
}
.togglersetting-wrap.activee span{
  position: absolute;
  left: 20px !important;
  background: #5fc791;
}

.chatinfoDropDown svg{
  margin-right: 5px;
}
.chatinfoDropDown {
padding: 0px !important;
border-radius: 5px;
    background: white;
    border: none !important;
    top: 40px !important;
    right: 7px !important;
    z-index: 9;
    border-radius: 6px !important;
    /* overflow: hidden; */
     border: 1px solid #d7d7d7 !important;
      z-index: 9999 !important;
}

.chatinfoDropDown >div{
 border-bottom: 1px solid #d7d7d7;
}
.chatinfoDropDown >div:last-child{
 border-bottom: none !important
}
.chatinfoDropDown::before{
       content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    right: 15px;
    top: -7px;
    transform: rotate(44deg);
    z-index: -1;
}


.chatinfoDropDown {
min-width: 170px;
top: 43px !important;
}
.chatinfoDropDown > div {
color: #666666bf;
padding: 3px 7px !important;
}
.chatinfoDropDown > div:hover {
background-color: rgba(52,154,244,.1803921568627451) !important;
}

.TopBarAnimated{
   padding: 10px 10px;
   border-bottom: 1px solid #c2c2c259;
   margin: 0px 0px !important;
}
.chat-iner-componets-left {
    margin: 20px 19px;
    padding-right: 46%;
}
.msg-wrap {
    margin: 0px 10px;
    height:calc(100% - 132px);
    overflow-y: auto;
    overflow-x: hidden;
    /* padding-bottom: 40px; */
    /* border-bottom: 1px solid #e3e3e3; */
    position: relative;
    margin-top: -25px;
}
.chat-iner-componets-right {
    margin: 20px 19px;
    padding-left: 46%;
    flex-direction: row-reverse;
}

.chat-iner-componets-right .round-shape {
    margin-left: 10px;
}


.panel .rooms {
    height: calc(100% - 55px - 41px - 140px) !important;
}

.bottom-bar-conversation{
  margin-bottom: 8px;
  border-bottom: 1px solid floralwhite;
  width: auto !important;
  margin: 10px;
}

.top-bar .nav .button{
      font-weight: 700 !important;
    color: #838383 !important;
    cursor: pointer !important;
    
}
.top-bar .nav .button.active svg{
   font-weight: 300 !important;
  color: rgb(255, 166, 60) !important;
}
.topBarHeight{
  padding: 35px 5px !important;
}


@media screen and (max-width: 480px) {
 .noti-info-wraper>div:first-child{
   margin-right: 2px;
 }
}



/* ************************* */
.chat-header-side-options {
        position: relative;
    width: 25px;
    height: 25px;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px;
    font-weight: bolder;
    cursor: pointer;
}

.chat-option-dropdown {
    display: none;
    position: absolute;
    background: white;
    width: 170px;
    right: -12px;
    top: 36px;
    border-radius: 5px;
    border: 1px solid;
    border-color: gainsboro;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .3) !important;
    padding: 0px 0px;
    z-index: 9999;
    /* overflow: hidden; */
    text-align: left !important;
    font-size: 14px;
}

.chat-option-dropdown.active {
    display: block;
}

.chat-option-dropdown:before {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    border-color: #e3e3e3;
    right: 15px;
    top: -9px;
    position: absolute;
    transform: rotate(
            -135deg
    );
    background: #ffffff;
    border-radius: 1px;
    z-index: -2;
}
.header-side-dropdown svg {
    transform: rotate(90deg);
        color: #bbb8b8;
    font-weight: 700;
    margin-top: -4px;
}



.chat-option-dropdown div {
    display: block;
    border-bottom: 1px solid #e3e3e3;
    padding: 6px 17px;
    font-weight: bold;
    cursor: pointer;
    color: #8c8c8c;
    cursor: pointer;
    z-index: 9999;

}

.chat-option-dropdown div:hover {
    background: #349af42e;

}
.FarwardSMS{
  /* background-color: red; */
  font-size: 10px;
  margin-bottom: -16px;
  z-index: 999;
  padding-left: 25px;
  color: #349bf4 !important;
  /* border-bottom:  1px solid  white; */

}

.chat-option-dropdown div:hover .chat-option-dropdown:before {
    background: #f1f9ff !important;
}

.chat-option-dropdown div svg {
    color: #656565 !important;
}

.chat-option-dropdown div:last-child {
    border-bottom: none;

}




/* ************************* */
.profileModal .modal-content{
  background-color: transparent;
  border:none
}
.profileModal .modal-body {
    display: flex;
    justify-content: center;
}
.profileModal .modal-header {
   display: none;
}

.schoolAdminTitle{
  color: #349bf4 !important;
    font-size: 13px;
    margin-bottom: 0;
    margin-right: 10px;
    font-style: italic;
    font-weight: 300;
}

.blockLoader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #2b6289de; /* Blue */
  border-radius: 50%;
  width: 15.5px;
  height: 15.5px;
  padding: 5px;
  display: inline-block;
  animation: spinaa 1s linear infinite;
  margin-left: -6px;
  margin-bottom: -3px;
}

@keyframes spinaa {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Date-time-element {
    text-align: center;
    margin: 17px 0px;
}
.DateHandler{
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 10px !important;
  z-index: 999 !important;
}
.messages-container{
  height: 100%;
  overflow-y: scroll;
}
.textTransform{
  text-transform: uppercase;
}

.Date-time-element span{
    text-align: center;
    padding: 8px 33px;
    border-radius: 6px;
    background: #dcf0fe;
    color: #2b6289;
    font-weight: 700;
    font-size: 13px;
}

.profile-details-iner {
    display: flex;
}
.profile-details-iner div:first-child {
    padding-right: 5px;
}

.porfile-wrap {
    background: #2b628b;
    color: #fff;
    font-size: 14px;
    padding: 10px;
    border-radius: 9px;
    min-width: 345px;
    /* max-width: 350px; */
    display: inline-block;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.3)!important;
}

.profile-imge {
    position: relative;
    margin-left: 15px;
      width: 70px;
    height: 70px;
     margin-top: -46px;
     border-radius: 50%;
         border: 1px solid #e2e2e2;
}
.profile-imge img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
   
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.3)!important;
}

.profile-imge .status-dot.waiting {
    width: 13px;
    height: 13px;
    background: #3fac00;
    display: block;
    position: absolute;
    left: 54px;
    border-radius: 50%;
    bottom: 3px;
    z-index: 99;
}
.user-details-container {
    color: #fff;
    font-weight: 700;
    padding: 3px 15px;
    margin-top: 10px;
}
.user-details-container {
    color: #fff;
    font-weight: 700;
    padding: 3px 15px;
    margin-top: 10px;
}
.user-details-container h4 {
    color: #fff;
    font-weight: 700;
    margin-bottom: 3px;
    text-transform: capitalize;
}
.porfile-user-list {
    display: flex;
    justify-content: start;
    text-align: left;
    font-weight: 500;
}
.porfile-user-list span:first-child {
    min-width: 100px;
}
.user-details-container h5 {
    color: #fff;
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: -7px;
}
.porfile-user-list span {
    min-width: 100px;
    margin-top: 4px;
    text-transform: capitalize;
}
.porfile-user-list span.emailOverflow{
    
    text-transform: unset !important;
}
.porfile-user-list svg {
    color: #49a0dd;
    font-weight: 700;
    margin: 0 5px 0 8px;
}
/* ************************* */
.rightActive{
  border-right: 4px solid #FE3576;
  background-color: #FEFAF9;
}
/* ************************* */

.messages-wrapper .message .content-x .bubble-left:before{
  left: -14px !important;
}
.messages-wrapper .message .content-x .bubble.right {
  background: #ecececfa !important;
  color: rgb(102, 102, 102) !important;
  font-size: 14.2px;
  cursor: pointer;
  padding: 13px;
}


.content-x.right .replySmsTextContainer {
  background: #ecececfa !important;
  color: rgb(102, 102, 102) !important;
  font-size: 14.2px;
  cursor: pointer;
  padding: 13px 0px;
      /* margin: 0 14px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -10px;
    z-index: 99; 
  /* border-left: 5px solid #42B8DF ; */
  /* border-top: 1px solid #2b62895d ; */
}
.content-x.right.imgReplyContainer .replySmsTextContainer {
      margin: 0 12.8px;
       margin-top: -10px;
}


.messages-wrapper .message .content-x .bubble.left {
  background: #ecececfa !important;
  color: rgb(102, 102, 102) !important;
  font-size: 14.2px; 
}
.messages-wrapper .message .content-x .bubble.right.replys {
  border-left: 5px solid #42B8DF ;
}
.messages-wrapper .message .content-x .bubble.left.replys {
  border-right: 5px solid #42B8DF ;
} 

.message.left  .replySmsTextContainer {
  background: #ecececfa !important;
  color: rgb(102, 102, 102) !important;
  font-size: 14.2px;
  cursor: pointer;
 padding: 13px 0px;
      /* margin: 0 14px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -10px;
    z-index: 99; 
  /* border-right: 5px solid #42B8DF ; */
  /* border-top: 1px solid #2b62895d ; */
}
.message.left .imgReplyContainer .replySmsTextContainer {
      margin: 0 12.8px;
       margin-left: 11px;
       margin-top: -10px;
}



/* ********reply css************* */
.sendSms-Wraper{
  position: relative;
}
.replyContainerView{
    /* background: #e7e7e7;
    border: 1px solid #d8d8d8;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: none; */
    /* margin: 0px 10px; */
    position: absolute;
    bottom: 47px;
    padding: 10px;
    z-index: 999;
    width: 100%;
    /* overflow: hidden; */
}
.replyContainerView > div{
  display: flex;
  align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 50px;
    background: #fff;
    padding: 5px 7px;
    position: relative;
    margin: 0px 10px;
}
.replyContainerView::before{
    content: "";
    border: 1px solid #d8d8d85d;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: none;
    /* width: 100%; */
    height: 110%;
    left: 10px;
    right: 10px;
    top: -0px;
    position: absolute;
    background: #e7e7e7;
    z-index: -1;
}

.replyContainerView > div > div:nth-child(1){
  /* width: 90px; */
}
.replyContainerView > div > div:nth-child(1) div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    overflow: hidden;
}
.replyContainerView > div > div:nth-child(1) img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.replyContainerView > div > div:nth-child(3){
  width: 100px;
  height: 50px;
  margin-right: 10px;
}
.replyContainerView > div > div:nth-child(3) img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
      border: 1px solid #e7e7e7;
}
.replyContainerView > div > div:last-child{
  width: 50px;
      display: flex;
    justify-content: center;
}
.replyContainerView > div > div:last-child svg{
 cursor: pointer;
}
.replyContainerView > div > div:nth-child(2){
  flex-grow: 1;
  padding: 0px 7px;
}
.replyContainerView > div > div:nth-child(2) h3 {
    font-weight: 700;
    font-size: 18px;
}
.replyContainerView > div > div:nth-child(2)  small {
    font-size: 10px;
    margin-top: 7px;
    position: absolute;
    margin-left: 5px;
    font-weight: 700;
}
.replyContainerView > div > div:nth-child(2)  p {
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    color: #000;
    font-size: 14.2px;

}



/* ********************* */
 

.messages-wrapper .message .content-x .bubble-right:before{
    border-left: 10px solid #ecececfa !important;
    border-top: 26px solid #ecececfa !important;
    border-bottom: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
}
.messages-wrapper .message .content-x .bubble-left:before{
    border-right: 10px solid #ecececfa !important;
    border-top: 26px solid #ecececfa !important;
    border-bottom: 15px solid transparent !important;
    border-right: 15px solid transparent !important;
}

/* .messages-wrapper .message .content-x .bubble-right:before {
    border-left: 10px solid #f8f8f8 !important;
    border-top: 26px solid #f8f8f8 !important;
    
} */

.messages-wrapper .message .picture img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
}

.messages-wrapper .spacer {
    width: 50px;
    height: 50px;
}

.messages-wrapper .spacer {
    width: 50px !important;
    height: 50px !important;
}
.content-x  {
  border-bottom:8px solid white !important;
}

.messages-wrapper .message .content-x .bubble.left {
    background: #ecececfa !important;
     cursor: pointer;
    color: #1b1818 !important;
        padding: 13px;
}

.messages-wrapper .message .content-x .bubble-left:before {
    border-right: 10px solid #ecececfa !important;
    border-top: 26px solid #ecececfa !important;
    
}

::-webkit-scrollbar {
  width: 0px;
}

.imjesContainer{
  cursor: pointer;
  padding: 5px;
  position: relative;
  display: none;
}
.imjesContainer > svg{
font-size: 20px;
}

.message:hover .imjesContainer{
  display: block;
}

 .ReplyIcon{
  display: none; 
  font-size: 18px;
  margin-top: 2px;
  padding: 0px 3px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.782);
}
.message:hover .ReplyIcon{
  display: block;
}

.imojesDropDown {
  position: absolute;
  display: none;
  background: white;
  width: 150px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  z-index: 9999;
    top: -34px;
    left: -10px;
    box-shadow: 1px 1px 5px 1px #00000026;
    padding: 5px;
}

.imjesContainer:hover .imojesDropDown{
  display: block;
}

.imojesDropDown::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white !important;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    left: 15px;
    bottom: -7px;
    transform: rotate(-140deg);
    /* z-index: -1; */
}

.imojesDropDown >div{
  display: flex;
  justify-content: space-between;
  z-index: 999;
  border-radius: 10px;
}
.imojesDropDown >div img{
width: 28px;
transition: 0.4s;
}
.imojesDropDown >div img:hover{
transform: scale(1.5);
}


.messages-wrapper .message .content-x .message-details {
    text-align: right;
}

.messages-wrapper .message .content-x .message-details.right {
    width: auto !important; 
}

.chatbnts {
    padding: 4px 9px;
    font-size: 13px;
    border-radius: 5px;
    color:#3c3a3a;
    font-weight: 500;
}

.chatbnts:hover {
  
    background: #edeff2;
}

div#wave .dot{
  width: 7px !important;
  height: 7px !important;
  background: #fe3274 !important;
}

.chatUerImgeDetails-Container{
  position:relative;
  cursor: pointer;
}
.chatUerImgeDetails-Container > img{
    border: 1px solid #e2e2e2;
}

.details-user-wrapper{
  display: none;
  position: absolute;
  background: white;
  width: 400px;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  z-index: 9999;
    top: 59px;
    left: -10px;
    box-shadow: 1px 1px 5px 1px #00000026;
    padding: 5px 10px;
    cursor: pointer;
}

.chatUerImgeDetails-Container:hover .details-user-wrapper{
  display: block;
}

.details-user-wrapper h4{
      font-family: ProximaNovaRegular !important;
      font-weight: bolder;
      margin-bottom: 0px;
      font-size: 20px !important;
}
.details-user-wrapper h4 svg{
     color: #ff9c25;
}
.details-user-wrapper p{
      font-family: ProximaNovaRegular !important;
      padding-bottom: 0px;
      color: #2b628a;
      font-size: 15px;
}
.details-user-wrapper > div{
    display: flex;
    /* align-items: center; */
}

.smsCont.act{
  border-right: 5px solid pink !important;
}


.details-user-wrapper > div >div:first-child .userStatusImge{
    height: 17px !important;
    width: auto !important;
    margin-top: -7px;
    margin-right: 4px;
}

.details-user-wrapper::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    left: 25px;
    top: -7px;
    transform: rotate(45deg);
    /* z-index: -1; */
}


.sendSms-Wraper{
  
  margin: 8px;
  margin-bottom: 5px;
}
.sendSmsContainer{
  padding: 25px 20px;
  background: #e7e7e7 !important;
  box-sizing: border-box;
  border-radius: 10px;
  border:none !important;
  
}
.sendSmsContainer .smile {
 padding-right: 10px !important;
}

.content-download {
    color: black !important;
}
.content-download > div:first-child svg {
    color: black !important;
    font-size: 40px;
    min-width: auto !important;
    width: auto !important;
    /* color: #a5a4a4; */
}

.content-download > div:last-child {
    margin-left: auto;
    color: #ff3559;
    font-size: 20px;
}


.messages-wrapper .message .content-x .bubble-image{
  width: auto !important;
}

.m-0{
  margin: 0px !important;
}
.filetuploader{
  transform: rotate(135deg);
}

.picker {
    left: -273px !important;
    z-index: 9999;
}

.sendsmsbtn{
  background: #ffe0e4;
  border-radius: 50%;
  transform: rotate(45deg);
  padding: 3px;
  color: #f23553;
}
.sendsmsbtn svg{
  padding-right: 2px;
  padding-top: 2px;
}
.sendsmsbtn:hover{
  background-color: #d7eec9;
}
.sendsmsbtn:hover svg{
color: #3fac00;
}
.bottom-bar-conversation .button {
margin:0px !important
}


.sendSmsContainer input:focus{
 outline: none !important;
}
/* ************************* */
.top-bar .profile.conversation .img {
    width: 50px !important;
    height: 50px !important;

}
@media(min-width: 576px){
.GralryModal .modal-dialog {
    max-width: 90% !important;
    margin: 0.75rem auto !important;
}
}

.GralryModal .modal-header {
    padding: 5px 1rem;
}

.GalaryHeader{
  display: flex;
  align-items: center;
}
.new-member-join {
    background: #edf7e7;
    margin: 10px 150px;
    text-align: center;
    padding: 7px 0;
    border-radius: 4px;
    color: #4c4c4c;
    font-size: 14px;
    font-weight: 700;
}
.GalaryHeader > div:first-child{
  width: 50px;
  height: 50px;
  background-color: rgb(181, 180, 180);
  border-radius: 50%;
  overflow:hidden;
      border: 1px solid #e2e2e2;
      margin-right: 8px;
}
.GalaryHeader > div:first-child img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}
.GalaryHeader > div:nth-child(2) h4{
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 20px;
}
.GalaryHeader > div:nth-child(2) p{
 font-size: 10px;
 font-weight: 600;
}

.GalaryMediaCarasole .slider-wrapper li div{
  height: 450px;
  background-color: white !important;
}

.GalaryMediaCarasole .thumbs{
  display: flex;
}
.GalaryMediaCarasole .thumbs li{
  height:50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.GalaryMediaCarasole .thumbs li img{
 height: 100%;
 width: auto;
}

.GalaryMediaCarasole .carousel.carousel-slider .control-arrow{
  background-color: black;
  bottom: unset;
  top: 45%;
  width: 45px;
  height: 53px;
}
.GalaryMediaCarasole .carousel .thumbs-wrapper {
    margin-bottom: -16px;
}
.GalaryMediaCarasole .control-dots {
display: none;
}
.GalaryMediaCarasole .slider-wrapper img{
  height: 100%;
  width: auto !important;
}
/* ************************* */
.search-bar .search input{
  font-size: 14.5px;
}
/* ************************* */
.side-filder-container {
    display: flex;
    flex-direction: column;
}
.close-chat-details {
    display: block;
    position: absolute;
    padding: 10px;
    background: #fff;
    width: 90%;
    height: 18px;
    z-index: 9;
    top: 0px;
}

.room .profile .img{
  width: 50px !important;
  height: 50px !important;
}
.close-chat-details svg {
    float: right;
    cursor: pointer;
}
.search-filter-media-dorop {
    display: block;
    position: relative;
    margin-top: 31px;
}
.searchContainer-dropdown {
    border: 1px solid #c2c2c2;
    padding: 7px 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: #858585;
    cursor: pointer;
    font-weight: 600;
}
.searchContainer-dropdown {
    border: 1px solid #c2c2c2;
    padding: 7px 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: #858585;
    cursor: pointer;
    font-weight: 600;
}
.searchContainer-dropdown {
    border: 1px solid #e3e3e3;
    padding: 7px 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    color: #858585;
    cursor: pointer;
    font-weight: 600;
}
.show-dorpDownas-container {
    cursor: pointer;
}
.searchContainer-dropdown svg {
    color: #858585;
}
.drop-down-container-list.hide {
    display: none;
}
.drop-down-container-list {
    display: block;
    background: hsla(0,0%,100%,.9098039215686274);
    border: 1px solid #e3e3e3;
    padding: 10px 0;
    position: absolute;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.3)!important;
    z-index: 9;
}
.drop-down-container-list span.active, .drop-down-container-list span:hover {
    border-left: 2px solid #3fad00;
     color: #858585 !important;
}
.drop-down-container-list span {
    display: block;
    color: #858585;
    font-weight: 700;
    border-left: 2px solid transparent;
    padding: 1px 14px;
    cursor: pointer;
}
.drop-down-container-list span.active, .drop-down-container-list span:hover {
    border-left: 2px solid #3fad00;
}
.gallery-container {
    margin-top: 9px;
}
.gallery-pictures {
    display: flex;
    flex-wrap: wrap;
}
.gallery-pictures div {
    margin: 5px;
    width: 28%;
    border-radius: 4px;
    height: 78.11px;
    border:1px solid #e2e2e28e;
background-color: white;
}
.gallery-pictures img {
       width: 100%;
    height: 100%;
    object-fit: cover;
}
.see-more-gallary {
    background: #ff4875;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    text-align: center;
    font-size: 11px;
    cursor: pointer;
}
.see-more-gallary div:first-child {
    font-size: 15px;
    margin-bottom: -5px!important;
}
.see-more-gallary div:last-child {
    font-size: 12px;
    width: 100%;
    margin-bottom: -9px!important;
}
.shared-links h2 {
    color: grey;
    font-weight: 500;
    font-size: 19.6px;
    margin-bottom: 1px;
    margin-top: 20px;
}
.links-container span.links-elements {
    display: block;
    margin-top: 9px;
    font-weight: 700;
}
.links-container a {
    color: grey;
    text-decoration: none !important;
    display: flex;
    align-items: center;
  }
.links-container a small{
font-weight: bold !important;
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis!important;
    display: inline-block;
    width: 50%;
  }
.fileshere.pdf {
    background: #ffe0e4;
    color: red!important;
}
.more-link-container {
    display: block;
    margin-top: 25px;
}
.more-link-container a {
    background: #ffe0e4;
    padding: 7px 23px;
    border-radius: 17px;
    font-weight: 700;
    font-size: 12px;
}
.more-link-container a, .more-link-container a:hover {
    color: #ff4875;
    text-decoration: none !important;
}
.fileshere {
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 9px;
    display: inline-block;
}
.links-container small {
    font-size: 13px;
}
.links-container a:hover {
    color: #ff4875;
    text-decoration: none;
}
.fileshere.zip {
    background: #faeedd;
    color: #bd8534!important;
}
.fileshere.doc {
    background: #dcf0fe;
    color: #2b6289!important;
}
/* ************************* */
.groups-imges {
    display: flex;
}
.groups-imges span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 3px;
}
.groups-imges img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.other-user-wrap {
    padding: 14px 6px;
    position: relative;
    cursor: pointer;
    font-size: 11px;
}
.other-user-wrap {
  padding: 14px 6px;
  position: relative;
  cursor: pointer;
  font-size: 11px;
}
.other-user-wrap:hover .more-users-container {
display: block;
}
.more-users-container {
    display: none;
    position: absolute;
    background: rgba(0,0,0,.7490196078431373);
    right: 4px;
    font-size: 12px;
    color: #fff;
    padding: 13px 15px;
    border-radius: 5px;
    top: 38px;
    z-index: 9999;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.3)!important;
    /* min-width: 146px; */
    /* width: 100%; */
    font-weight: 100;
}

.emailOverflow{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.more-users-container:before {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    right: 18px;
    top: -7px;
    position: absolute;
    transform: rotate(
43deg
);
    background: #404040;
    border: 1px solid #404040;
    z-index: -2;
}
.GroupMemberListShow div {

    white-space: nowrap
}
.GroupMemberListShow div:hover {
    cursor: pointer;
    color: #ffdd3d;
}
/* ************************* */

.profile img{
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
/* ************************* */
.chatContainerWrapper {
  position: relative;
}
.chatContainerWrapper .messages-wrapper ,.chatContainerWrapper .sendSms-Wraper {
  margin-right: 300px;
   transition: 0.5s all;
}
.chatContainerWrapper.unactive .messages-wrapper ,.chatContainerWrapper.unactive .sendSms-Wraper {
  margin-right: 0px !important;
}

.chatContainerWrapper .mediaContainer{
  position: absolute;
  right: -300px;
  top: 142px;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  border-left: 1px solid #e3e3e3;
  padding: 10px;
  padding-bottom: 150px;
  transition: 0.5s all;
}
.chatContainerWrapper .mediaContainer.active{
  right: 0px;
}
.closechat-icon {
    color: #858585;
}

.farwardbtn{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* ************************* */

.allUserList-wrap {
    padding-bottom: 11px;
    height: 70px !important;
}
.allUserList-wrap:hover {
    background-color: #e2ecf7 !important;
}

.useinfodropDownContainer{
position: relative;
}
.useinfodropDownContainer > span{
    border: 1px solid #8c8c8c;
    padding: 0px !important;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white !important;
}
.userinfodorpdown-Wrap{
  display: none;
  position: absolute;
  min-width: 220px;
  padding: 0px !important;
  background: white;
  border: none !important;
  top: 48px !important;
  right: 0px !important;
  z-index: 9;
  text-align: left;
  border-radius: 6px !important;
  /* overflow: hidden; */
  border: 1px solid #d7d7d7 !important;
  z-index: 999999 !important;
  color: black;
  
}

.useinfodropDownContainer:hover .userinfodorpdown-Wrap{
  display: block !important;
}

.userinfodorpdown-Wrap > div {
display: flex;
flex-direction: column;
}
.userinfodorpdown-Wrap >div> div {
  padding: 5px 10px;
  border-bottom: 1px solid #d7d7d7 !important;
  display: flex;
  align-items: center;
  /* text-align: ; */
  /* justify-content: space-between; */
  font-weight: 700;
}
.userinfodorpdown-Wrap >div img {
 margin-right: 5px;
 width: 20px;
}
.userinfodorpdown-Wrap >div> div:nth-child(1) {
color: #f13554;
}
.userinfodorpdown-Wrap >div> div:nth-child(2) {
color: #2b6289;
}
.userinfodorpdown-Wrap >div> div:nth-child(3) {
color: #ba7f2b;
}
.userinfodorpdown-Wrap >div> div:nth-child(4) {
color: #433a5e;
}

.userinfodorpdown-Wrap >div> div:hover {
background: #f8f8f9;
}
.userinfodorpdown-Wrap >div> div:last-child {
padding: 3px 10px;
border-bottom: none !important;
}
.userinfodorpdown-Wrap::before{
      content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: white;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #d7d7d7;
    right: 10px;
    top: -7px;
    transform: rotate(44deg);
    z-index: -1;
}

.block-elements .block-danger {
  color: red !important;
  opacity: 0.6;
  cursor: pointer;
}

.block-elements .block-danger.blocked {
  display: none;
}

.block-elements .block-success {
  color: green !important;
  opacity: 0.6;
  cursor: pointer;
}

.block-elements .block-success.blocked {
  display: none;
}

.block-elements {
  position: relative;
  top: 1px;
  margin-left: -5px;
}

/* ************************* */


